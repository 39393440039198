

export const apiUrl = process.env.NEXT_PUBLIC_API_URI || "https://wescaleleads.com/graphql/";

export const appToken = process.env.NEXT_PUBLIC_APP_TOKEN || process.env.APP_TOKEN || "wrGnyHaW0PHlqLJSDUlKOeATRq7RH9"

export const scriptUrl = process.env.NEXT_PUBLIC_SCRIPT_URI || process.env.SCRIPT_URL || "online|menpro";

//export const campaignId = process.env.NEXT_PUBLIC_CAMPAIGN_ID || "1tzmXfHCxOwMpu6RvaAnX1Etgyl"//"1nHpzeDBoBM1n1ksHoP4F3e6tcM";

export const websiteDomain = process.env.NEXT_PUBLIC_WEBSITE_DOMAIN || "https://netlycorder.vercel.app"//"https://trimhouse.vercel.app";


export const serviceWorkerTimeout =
  parseInt(process.env.SERVICE_WORKER_TIMEOUT || "", 10) || 60 * 1000;

export const channelSlug = process.env.NEXT_PUBLIC_SALEOR_CHANNEL_SLUG || "default-channel";

export const exportMode = process.env.NEXT_EXPORT === "true";

export const ssrMode = typeof window === "undefined";

export const incrementalStaticRegenerationRevalidate = false

export const staticPathsFetchBatch = 100;

export const staticPathsFallback = (exportMode
  ? false
  : process.env.NEXT_PUBLIC_STATIC_PATHS_FALLBACK) | "blocking";


export const storeId = process.env.NEXT_PUBLIC_SALEOR_STORE_ID || process.env.STORE_ID || "sportswear_co_1"//"regieu"//"udobr1"//"regi1"//"trim1"

export const productsPerPage = 12;

export const locale = (process.env.NEXT_PUBLIC_SALEOR_LOCALE || process.env.LOCALE || "en").toLowerCase()

export const currency = (process.env.NEXT_PUBLIC_CURRENCY || process.env.CURRENCY || "").toLowerCase()

export const currencyMult = ((process.env.NEXT_PUBLIC_CURRENCY_MULT || process.env.CURRENCY_MULT) ? parseFloat(process.env.NEXT_PUBLIC_CURRENCY_MULT || process.env.CURRENCY_MULT) : 1)

export const faviconDefault = "/favicon.ico"

export const yaMetrika = (process.env.NEXT_PUBLIC_YANDEX_METRIKA || process.env.YANDEX_METRIKA || "").toLowerCase()


export const META_DEFAULTS = {
  custom: [],
  description:
    "Default description",
  image: "",
  title: "Northwest Seafood Express",
  type: "website",
  url: ((websiteDomain|| "").startsWith("http") ? "" : "https://") + websiteDomain,
};
