import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  backAction: {
    defaultMessage: "Go back to home"
  },
  privacyPolicy: {
    defaultMessage: "Privacy policy"
  },
  termsOfUse: {
    defaultMessage: "Terms of use"
  },
  moreProductsAction: {
    defaultMessage: "Load more products"
  },
  outOfStock: {
    defaultMessage: "Out of stock"
  },
  lowStock: {
    defaultMessage: "Low stock"
  },
  noItemsAvailable: {
    defaultMessage: "No items available"
  },
  noPurchaseAvailable: {
    defaultMessage: "Not available for purchase"
  },
  purchaseAvailableOn: {
    defaultMessage: `Will become available for purchase on {date} at {time}`
  },
  notFoundDescription: {
    defaultMessage: "We can’t seem to find a page you are looking for!"
  },
  attributes: {
    defaultMessage: "Attributes"
  },
  description: {
    defaultMessage: "Description"
  },
  homePage: {
    defaultMessage: "Home"
  },
  deliveryPage: {
    defaultMessage: "Delivery"
  },
  paymentPage: {
    defaultMessage: "Payment"
  },
  contactsPage: {
    defaultMessage: "Contacts"
  },
  cartPage: {
    defaultMessage: "Cart"
  },
  loginPage: {
    defaultMessage: "Login"
  },
  signUpPage: {
    defaultMessage: "Sign up"
  },
  rightsReserved: {
    defaultMessage: "All rights reserved"
  },
  loading: {
    defaultMessage: "Loading"
  },
  noProducts: {
    defaultMessage: "No products"
},
  addToCartAction: {
      defaultMessage: "Add to cart"
  },
  cookieText: {
      defaultMessage: "We use cookie to make the site simpler"
  },
  cookieAccept: {
      defaultMessage: "Continue"
  }
});

export const inputMessages = defineMessages({
  name: {
    defaultMessage: "Name"
  },
  nameHelper: {
    defaultMessage: "Enter your name"
  },
  email: {
    defaultMessage: "Email"
  },
  emailHelper: {
    defaultMessage: "Enter your email"
  },
  password: {
    defaultMessage: "Password"
  },
  passwordHelper: {
    defaultMessage: "Enter your password"
  },
  passwordRepeat: {
    defaultMessage: "Password Repeat"
  },
  passwordRepeatHelper: {
    defaultMessage: "Repeat your password"
  }
});
